<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>


</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p>info@tristatebenefits.nyc</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p>9920 Fourth Avenue, Suite 310, Brooklyn, NY 11209</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p>+1 (844) 387-4782</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Coverage Needs!</h2>
            <p>Contact Us Today To Speak With One Of Our Experts</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div >
                    <img src="assets/images/logo.png" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>