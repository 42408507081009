<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Team</li>
            </ul>
        </div>
    </div>

    <!-- <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div> -->
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
            <p>Meet our Tri-State Benefits Team Members</p>
        </div>

        <div class="row">
        


            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/logo1.png" alt="team">
                    </div>
                    
                    <div class="team-content">
                        <h3>Elizabeth LaRocco</h3>
                        <p>Founder/Owner</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/logo1.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Aurora Sylvestri</h3>
                        <p>Marketing Manager</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/logo1.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Eva Marie Boyce</h3>
                        <p>Sales Manager</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            </div>
        </div>
</section>