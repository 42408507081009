<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Paid Benefits</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Products</li>
            </ul>
        </div>
    </div>


</div>

<br>
<br>
<br>

<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Tristate Union Benefits</span>
            <h2>Paid Benefits Offered</h2>
            <p> We work with all of the major insurance companies to competitively bid out all of your insurance needs.  In a competitive economic market, every dollar counts.  We’ve learned that benefits should be analyzed annually, and put out to bid on a frequent basis, to ensure that our clients are paying the lowest premium necessary to provide their members/employees with what the need to protect themselves and their families.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/uh.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Term Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Universal Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/gt.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Whole Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Guaranteed Issue Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/po.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Long Term Disability Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family-insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Dental Plans</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family-insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Vision Plans</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/care.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>	Accidental Death and Dismemberment Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Term Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/lo.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Critical Illness Protection</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>