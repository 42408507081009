<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Tristate Union Benefits</h1>
                                            <p>Tri-State Union Benefits is a group benefits  brokerage specializing in the design, implementation, and administration of voluntary benefits programs and employer paid group benefits. We offer an array of unique products to supplement existing employer-paid benefits.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Fill in the gaps</h1>
                                            <p>These voluntary supplemental benefits “fill the gaps” in their employees/members core benefits, allowing your members to choose which ones are best for them and their families without you bearing the costs.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
  
        </owl-carousel-o>
    </div>
</section>

<div class="info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> About Us</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i> Our Services</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Our Goal</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Tristate Union Benefits</span>
            <h2>Paid Benefits Offered</h2>
            <p> We work with all of the major insurance companies to competitively bid out all of your insurance needs.  In a competitive economic market, every dollar counts.  We’ve learned that benefits should be analyzed annually, and put out to bid on a frequent basis, to ensure that our clients are paying the lowest premium necessary to provide their members/employees with what the need to protect themselves and their families.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/uh.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Term Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Universal Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/gt.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Whole Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Guaranteed Issue Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/po.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Long Term Disability Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/payment.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Prescription Drug Plans</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family-insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Dental Plans</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/family-insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Group Vision Plans</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/care.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Accidental Death and Dismemberment Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/insurance.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Individual Term Life Insurance</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/lo.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Critical Illness Protection</h3>
                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Process</span>
            <h2>Integral Services</h2>
            <p style="font-size: 18px;">Tri-State is committed to providing voluntary supplemental benefits that are a cost-effective solution for your organization while affordable for employees/members. You can focus on the business of managing your organization and we can assist your Human Resources department with maximizing benefits and profits.  We are focused on keeping your benefits cost as inexpensive as possible so that you can offer substantially better plans for the same costs you are paying today.</p>
            <br>
             <p style="font-size: 18px;">Whether it is voluntary benefits or a review of your current plans, contact us today to schedule a no-obligation, complimentary review of all your existing benefit plans.
                 Contact Us Today!</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Review of Existing Insurance Programs</h3>
                       
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Insurance Benefit Design</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Program Implementation</h3>
                    </div>
                </div>
            </div>
         
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Benefit Communication/Administration</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Tristate Union Benefits</span>
            <h2>Voluntary Insurance Benefits Offered</h2>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Permanent Life Insurance</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Disability Income Protection</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Cancer Care Indemnity</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Pension Maximization Coverage</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Long Term Care</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Hospital Indemnity Coverage</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Term Life Insurance</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Refund of Premium Term Life Insurance</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Accidental Death and Dismemberment Insurance</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Individual Dental Plans</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Individual Vision Plans</h3>
                </div>
            </div>
        
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="service-img">
                        <img src="assets/images/service/ty.png" alt="service" />
                    </div>
                    <h3>Critical Illness Protection</h3>
                </div>
            </div>
        </div>
    </div>

 


</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>What We Do</span>
                        <h2>Are you interested in?</h2>
                        <p style="font-size: 18px;">Benefit administration technology that requires no commitment of personnel or resources by your organization.
                            Benefit communication technology that explains and enrolls employees/members in the program of their choosing.
                            Providing voluntary supplemental benefit insurance programs to every employee/member of your organization regardless of current or previous medical conditions.
                            Strengthening employee/member loyalty to your organization.
                            </p>
                        <p>

                            </p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>




<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>Meet Our Team of Experts</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
              
        
                     
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/logo1.png" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Elizabeth LaRocco</h3>
                            <p>Founder/Owner</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/logo1.png" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Donald Boyce</h3>
                            <p>President</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/logo1.png" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Aurora Sylvestri</h3>
                            <p>Marketing Manager</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/logo1.png" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Eva Marie Boyce</h3>
                            <p>Sales Manager</p>
                        </div>
                    </div>
                </ng-template>
       
   
           
            </owl-carousel-o>
        </div>
    </div>
</section>



<section class="home-contact-area home-2-contact ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Benefit Needs!</h2>
            <p>Contact Us Today to Speak to One of Our Specialists</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="test">
                    <img src="assets/images/logo1.png" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>